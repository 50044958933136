<script setup lang="ts">
useHead({
  meta: [
    {
      hid: "viewport",
      name: "viewport",
      content: "width=device-width, initial-scale=0.55, minimum-scale=0.55",
    },
  ],
});

// composables
const { setLocale } = useI18n({
  useScope: "local",
});

const options = useQueryValueObject<PrintParams["options"]>("options");

const locale = ref(options.value?.locale ?? "ar");

// watch
watch(
  () => locale.value,
  (locale) => {
    setLocale(locale);
  },
  { immediate: true }
);

// methods
const changeLocale = (newLocale: string) => {
  locale.value = newLocale;
};
</script>

<template>
  <MpLayout @setLocale="changeLocale">
    <slot />
  </MpLayout>
</template>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}
html,
body {
  background-color: white;
  color: black;
}
</style>
